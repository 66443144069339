<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body menu">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item label="">
                                <el-input v-model="Keyword" type="text" size="small" placeholder="商品名称"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出{{DataCount}}条数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-for="(menu, index) in menuList" :key="index" :label="menu.label" :name="menu.value">
                        <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 40px)" :header-cell-style="{background:'#F5F7FC'}">
                            <el-table-column prop="commodity.name" label="商品名称" width="250px">
                            </el-table-column>
                            <el-table-column prop="soldNum" label="总销量" align="center">
                            </el-table-column>
                            <el-table-column prop="soldPrice" label="销售总金额（元）" align="center">
                            </el-table-column>
                            <el-table-column prop="surplusNum" label="剩余库存" align="center">
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="100px">
                                <template slot-scope="scope">
                                    <span class="primary optionBtn" @click="DetailBtn(scope.row)">明细</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                            :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { STATS } from "../../../../components/HospitalDomain/STATS"
import bread from "../../../../components/common/breadcrumb";

export default {
    name: "OwnStats",
    components: {
        bread
    },
    data() {
        var statistics = new STATS(this.TokenClient, this.Services.Shopping)
        return {
            StatisticsDomain: statistics,
            tableData: [],
            activeName: '%20',
            menuList: [
            {
                    label: "全部",
                    value: "%20",
                },
                {
                    label: "普通商品",
                    value: "1",
                },
                {
                    label: "积分换购商品",
                    value: "3",
                },
                {
                    label: "处方药",
                    value: "4",
                }
            ],
            Keyword: "",
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            title: '商品销售统计',
            DataCount: 0,
            json_fields: {
                "商品名称": {
                    callback: (val) => {
                        return val.commodity.name
                    }
                },
                "总销量": 'soldNum',
                "销售金额": 'soldPrice',
                "剩余库存": 'surplusNum',
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(tab, event) {
            this.PageIndex = 1;
            this.getList();
        },
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                _this.StatisticsDomain.CommodityStatisticsList(this.activeName,1, this.Keyword, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        console.log(err);
                        resolve('');
                    });
            })

        },
        getList() {
            this.StatisticsDomain.CommodityStatisticsList(this.activeName,this.PageIndex, this.Keyword, null,
                (data) => {
                    this.tableData = data.data.results;
                    this.PageIndex = data.data.pageIndex;
                    this.PageSize = data.data.pageSize;
                    this.PageTotal = data.data.pageCount;
                    this.DataTotal = data.data.dataTotal;
                        this.DataCount = data.data.dataTotal;
                }, (err) => {
                    console.log(err);
                })
        },
        DetailBtn(row) {
            this.$router.push({
                name: "SalesDetail",
                query: {
                    commodityId: row.id
                }
            })
        }
    }
}
</script>

<style scoped>
/*content*/


.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}
.menu /deep/ .el-tabs {
    height: calc(100% - 50px);
}

.menu /deep/ .el-tabs__content {
    height: calc(100% - 55px);
}

.menu /deep/ .el-tab-pane {
    height: 100%;
}
</style>
